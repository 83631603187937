<template>
  <b-modal id="modal-supplier" size="lg" title="Detail Supplier">
    <b-row>
      <b-col cols="12">
        <div class="mb-2">
          <label>Nama Supplier</label>
          <b-col cols="12" class="custom__input pb-25 pt-25">
            {{ detailResult.name || '-' }}
          </b-col>
        </div>

        <b-row class="m-0">
          <b-col lg="6" class="p-0">
            <div class="mb-2">
              <label>No. Telepon Kantor</label>
              <b-col cols="12" class="custom__input pb-25 pt-25">
                {{ detailResult.phone_number || '-' }}
              </b-col>
            </div>
          </b-col>
          <b-col lg="6" class="p-0">
            <div class="mb-2 ml-2">
              <label>No. NPWP</label>
              <b-col cols="12" class="custom__input pb-25 pt-25">
                {{ detailResult.tax_number || '-' }}
              </b-col>
            </div>
          </b-col>
        </b-row>

        <div class="mb-2">
          <label>Alamat</label>
          <b-col cols="12" class="custom__input pb-25 pt-25">
            {{ detailResult.address || '-' }}
          </b-col>
        </div>

        <b-row class="m-0">
          <b-col lg="6" class="p-0">
            <div class="mb-2">
              <label>Nama Sales</label>
              <b-col cols="12" class="custom__input pb-25 pt-25">
                {{ detailResult.sales_name || '-' }}
              </b-col>
            </div>
          </b-col>
          <b-col lg="6" class="p-0">
            <div class="mb-2 ml-2">
              <label>No. Telepon Sales</label>
              <b-col cols="12" class="custom__input pb-25 pt-25">
                {{ detailResult.sales_phone_number || '-' }}
              </b-col>
            </div>
          </b-col>
        </b-row>

        <div class="mb-2">
          <label>Tipe Pembayaran</label>
          <b-col cols="4" class="custom__input pb-25 pt-25">
            {{ detailResult.payment_type || '-' }}
          </b-col>
        </div>

        <div class="mb-2">
          <label>Tempo (Hari)</label>
          <b-col cols="2" class="custom__input pb-25 pt-25">
            {{ detailResult.terms_of_payment_day || '-' }}
          </b-col>
        </div>
      </b-col>
    </b-row>
    <template #modal-footer="{}">
      <b-button variant="primary" @click="$bvModal.hide('modal-supplier')">
        Close
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapState } from 'vuex'
import {
  BModal, BContainer, BRow, BCol, BButton,
} from 'bootstrap-vue'

import moment from 'moment'
import BaseInput from '@/components/BaseInput.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BContainer,
    BaseInput,
  },
  props: {
    getData: {
      type: Function
    },
    result: {
      type: Object
    },
    detailResult: {
      type: Object
    },
    getDetail: {
      type: Function
    },
  },
  methods: {
    getPhoto(value) {
      this.formPayload.logo = value.photo
    },
    directPage() {
      //   this.cleanUpForm()
      this.$bvModal.hide('modal-supplier')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables/_variables.scss';

.custom__input {
  background: #fff;
  border: 1px solid #E4E5EC;
  border-radius: 8px;
  color: #2B324F;
  font-weight: 500;
  font-size: 14px;
}
</style>